import React from "react";

export default function SearchBoxInputView({ getAutocomplete, getInputProps, getButtonProps }) {
  return (
    <>
      <input
        {...getInputProps({
          className: "some_custom_input_class"
        })}
      />
      {getAutocomplete()}
      <input
        {...getButtonProps({
          className: "some_custom_button_class",
          value: "OK"
        })}
      />
    </>
  );
}
