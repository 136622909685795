import React, { useRef, useEffect, useState } from 'react';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import departements from './../departements'
import _ from 'lodash'
import geohash from 'ngeohash'
import Spinner from './Spinner'
import wordings from './../wordings';

import { withSearch } from "@elastic/react-search-ui";

mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JlZ29pcmVkYXZpZCIsImEiOiJja29zYjVjM3kwbXB0Mm9vaGR5azhxcGp2In0.mhMlAQv8NCvnyDHekhFlFg';

function getCountByDepartement(facets) {
  if (facets && facets.departement && facets.departement[0] && facets.departement[0].data) {
    return facets.departement[0].data
  }
  return null;
}

function getCurrentDepartementFilter(filters) {
  const departementFilter = _.find(filters, ['field', 'departement']);
  if (departementFilter) {
    const [,currentCode] = departementFilter.values[0].match(/^(\d{1,2}[\dAB])\s-/);
    return currentCode;
  }
  return null;
}

function getBoundingBox(bounds) {
  // round to 6 decimal places.
  return {
    top_left: {
      lat: (Math.round(bounds.getNorthWest().lat * 1000000) / 1000000),
      lon: (Math.round(bounds.getNorthWest().lng * 1000000) / 1000000),
    },
    bottom_right: {
      lat: (Math.round(bounds.getSouthEast().lat * 1000000) / 1000000),
      lon: (Math.round(bounds.getSouthEast().lng * 1000000) / 1000000),
    }
  };
};

export function MapContainer({ totalResults, isLoading, geoData, filters, facets, addFilter, setFilter, removeFilter, geo }) {
  const countByDepartement = getCountByDepartement(facets);
  const currentDepartement = getCurrentDepartementFilter(filters);
  const bbox = (_.find(filters, ['field', 'geo_bounding_box'])) ? JSON.parse(_.find(filters, ['field', 'geo_bounding_box']).values[0]) : null;
  // const activeBbox = (_.find(filters, ['field', 'geo_bounding_box'])) ? true : false;

  const mapElement = useRef(null);
  // const map = useRef(null);
  const [map, setMap] = useState(null);
  // const [currentDepartement, setCurrentDepartement] = useState(null);
  // const [lng, setLng] = useState(1.75);
  // const [lat, setLat] = useState(47.11);
  // const [zoom, setZoom] = useState(5);
  const zoom = 5;
  const lat = 47.11;
  const lng = 1.75;

  useEffect(() => {
    const mapOptions = {
      container: mapElement.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: zoom
    };
    if (bbox) {
      mapOptions.bounds = [
        [bbox.bottom_right.lon, bbox.bottom_right.lat],
        [bbox.top_left.lon, bbox.top_left.lat],
      ];
    }

    const map = new mapboxgl.Map(mapOptions);

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    map.on('load', () => {
      map.setLayoutProperty('country-label', 'text-field', [
        'get',
        'name_fr'
      ]);

      // Data sources.
      map.addSource('clusters', {
        'type': 'geojson',
        'data': {type: 'FeatureCollection', features: []},
        'generateId': true,
      });
      map.addSource('points', {
        'type': 'geojson',
        'data': {type: 'FeatureCollection', features: []},
      });
      map.addSource('departements', {
        'type': 'geojson',
        'data': {type: 'FeatureCollection', features: []},
      });

      // Layers.
      map.addLayer({
        'id': 'departements',
        'source': 'departements',
        'maxzoom': 7,
        'type': 'fill',
        'paint': {
          'fill-color': '#006ab4', // bleu anfa
          'fill-opacity': ['get', 'opacity']
        }
      }, 'waterway-label');
      map.addLayer({
        'id': 'clusters',
        'source': 'clusters',
        'type': 'circle',
        'paint': {
          // 'circle-color': '#ffd333', // jaune
          // 'circle-color': '#8bc34a', // vert
          'circle-color': '#ff9100', // orange
          // 'circle-radius': ['get', 'size'],
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            // min zoom
            0, ["*", .05, ['get', 'size']],
            // max zoom
            22, ["*", 4, ['get', 'size']],
          ],
          // 'circle-opacity': .66,
          'circle-opacity': [
            'interpolate', ['linear'], ['zoom'],
            // min zoom
            0, .33,
            // max zoom
            22, 1,
          ],
          // 'circle-opacity': ['get', 'opacity']
        },
      }, 'waterway-label');
      map.addLayer({
        'id': 'clusters-highlighted',
        'source': 'clusters',
        'type': 'circle',
        'paint': {
          'circle-color': '#ffd600', // jaune
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            // min zoom
            0, ["*", .05, ['get', 'size']],
            // max zoom
            22, ["*", 4, ['get', 'size']],
          ],
          'circle-opacity': 1,
        },
        'filter': ['==', ['get', 'id'], ''],
      }, 'waterway-label');
      map.addLayer({
        'id': 'points',
        'source': 'points',
        'minzoom': 7,
        'type': 'circle',
        'paint': {
          'circle-color': '#8bc34a', //
          'circle-radius': 3,
          'circle-opacity': .8,
        },
      }, 'waterway-label');

      setMap(map);

      map.on('mousemove', 'clusters', function (e) {
        map.getCanvas().style.cursor = 'pointer';
        let props = e.features[0].properties;
        map.setFilter('clusters-highlighted', ['==', ['get', 'id'], props.id]);
        popup.setLngLat(e.lngLat)
          .setHTML((props.count > 1) ? `${props.count} offres` : '1 offre')
          .addTo(map);
      });

      map.on('mouseleave', 'clusters', function () {
        map.setFilter('clusters-highlighted', ['==', ['get', 'id'], '']);
        map.getCanvas().style.cursor = '';
        popup.remove();
      });


    });

    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter on zoom effect hook.
  useEffect(() => {
    if (!map) return;

    function updateGeoBoundingBox() {
      setFilter('geo_bounding_box', JSON.stringify(getBoundingBox(map.getBounds())), 'all');
    }

    if (bbox) {
      map.on('moveend', updateGeoBoundingBox);
    }
    return () => map.off('moveend', updateGeoBoundingBox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbox]);

  // Data update effect hook.
  useEffect(() => {
    if (!map) return;

    const points = {type: 'FeatureCollection', features: []};
    if (geo) {
      points.features = geo.map((row) => {
        return {
          type: 'Feature',
          properties: {
            intitule: row.intitule,
            id: row.id,
          },
          geometry: {
            type: 'Point',
            coordinates: row.geolocalisation
          }
        };
      });
    }
    map.getSource('points').setData(points);

    const clusters = {type: 'FeatureCollection', features: []};
    if (geoData && geoData.buckets.length) {
      clusters.features = geoData.buckets.map(function(row) {
        var point = geohash.decode(row.key);
        return {
          type: 'Feature',
          properties: {
            id: row.key,
            count: row.doc_count,
            size: 2 + (row.doc_count / 4),
            // opacity: (row.doc_count > 2) ? .5 : .8,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              point.longitude, point.latitude
            ]
          }
        };
      });
    }
    map.getSource('clusters').setData(clusters);

    if (currentDepartement) {
      departements.features = departements.features.map((feature) => {
        feature.properties.opacity = (feature.properties.code === currentDepartement) ? 0.5 : 0;
        return feature;
      });
    }
    else {
      if (countByDepartement && totalResults > 0) {
        const counts = countByDepartement.reduce((acc, facet) => {
          if (facet.value !== wordings.emptyLabel) {
            let matches = facet.value.match(/^(\d{1,2}[\dAB])\s-/);
            if (matches) {
              const [,code] = facet.value.match(/^(\d{1,2}[\dAB])\s-/);
              acc[code] = facet.count;
            }
          }
          return acc;
        }, {});
        const max = _.max(_.values(counts));
        departements.features = departements.features.map((feature) => {
          feature.properties.count = counts[feature.properties.code] ? counts[feature.properties.code] : 0;
          feature.properties.opacity = feature.properties.count ? Number.parseFloat(((feature.properties.count / max) / 2).toPrecision(4)) : 0;
          return feature;
        });
      }
      else {
        departements.features = departements.features.map((feature) => {
          feature.properties.opacity = 0;
          return feature;
        });
      }
    }
    map.getSource('departements').setData(departements);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countByDepartement, currentDepartement, map, geoData, geo]);


  function onClickCheckbox() {
    if (bbox) {
      removeFilter('geo_bounding_box');
    }
    else {
      setFilter('geo_bounding_box', JSON.stringify(getBoundingBox(map.getBounds())), 'all');
    }
  };

  return (
    <div className="map">
      <div className="map-toolbar">
        <label htmlFor="filter_on_zoom"><input id="filter_on_zoom" type="checkbox" checked={Boolean(bbox)} onChange={onClickCheckbox} /> Limiter la recherche au périmètre affiché sur la carte</label>
      </div>
      {isLoading && <Spinner />}
      <div ref={mapElement} className="map-container" />
    </div>
  );
}



export default withSearch(
  ({ totalResults, isLoading, geoData, filters, facets, addFilter, setFilter, removeFilter, geo, a11yNotify }) => ({
    totalResults,
    isLoading,
    geoData,
    filters,
    facets,
    addFilter,
    setFilter,
    removeFilter,
    geo,
    a11yNotify
  })
)(MapContainer);
