function getValueFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || bucket.key,
          count: bucket.doc_count
        }))
      }
    ];
  }
}

/*
function getRangeFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "range",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: {
            to: bucket.to,
            from: bucket.from,
            name: bucket.key
          },
          count: bucket.doc_count
        }))
      }
    ];
  }
}
*/

export default function buildStateFacets(aggregations) {
  const region = getValueFacet(aggregations, 'region');
  const departement = getValueFacet(aggregations, 'departement');
  const alternance = getValueFacet(aggregations, 'alternance');
  const anfa_famille = getValueFacet(aggregations, 'anfa_famille');
  const appellation_libelle = getValueFacet(aggregations, 'appellation_libelle');
  const anfa_categorie = getValueFacet(aggregations, 'anfa_categorie');
  const secteur_activite = getValueFacet(aggregations, 'secteur_activite');
  const anfa_contrat = getValueFacet(aggregations, 'anfa_contrat');
  const experience_exige = getValueFacet(aggregations, 'experience_exige');

  const facets = {
    ...(region && { region }),
    ...(departement && { departement }),
    ...(alternance && { alternance }),
    ...(anfa_famille && { anfa_famille }),
    ...(appellation_libelle && { appellation_libelle }),
    ...(anfa_categorie && { anfa_categorie }),
    ...(secteur_activite && { secteur_activite }),
    ...(anfa_contrat && { anfa_contrat }),
    ...(experience_exige && { experience_exige }),
  };

  if (Object.keys(facets).length > 0) {
    return facets;
  }
}
