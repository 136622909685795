import React from "react";

import { Layout } from "@elastic/react-search-ui-views";
import ReactTooltip from 'react-tooltip';

import {
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";

import MultiCheckboxFacet from './components/MultiCheckboxFacet'
import SortOptions from './SortOptions'
import SearchBoxInputView from './components/SearchBoxInputView'
import ResultView from './components/ResultView'
import MapContainer from './components/MapContainer'
import ActiveOffersCheckboxContainer from './components/activeOffersCheckboxContainer'
import Spinner from './components/Spinner'

import 'react-slidedown/lib/slidedown.css'

import "@elastic/react-search-ui-views/lib/styles/styles.css";


// Elastic connector
// @see https://github.com/elastic/search-ui/tree/master/examples/elasticsearch/src
import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import runMapRequest from "./runMapRequest";
import buildState from "./buildState";

const elasticConfig = {
  // debug: true,
  onSearch: async state => {
    const { resultsPerPage } = state;
    const requestBody = buildRequest(state);
    // Note that this could be optimized by running all of these requests
    // at the same time. Kept simple here for clarity.
    const responseJson = await runRequest(requestBody);
    const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
      responseJson,
      state,
      ['region', 'departement', 'alternance', 'anfa_famille', 'appellation_libelle', 'anfa_categorie', 'secteur_activite', 'anfa_contrat', 'experience_exige']
    );
    if (false && responseJsonWithDisjunctiveFacetCounts.hits.total.value < 5000) {
      const responseJsonWithGeo = await runMapRequest(responseJsonWithDisjunctiveFacetCounts, requestBody);
      return buildState(responseJsonWithGeo, resultsPerPage);
    }
    return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
  },
  alwaysSearchOnInitialLoad: true,
  initialState: {
    filters: [
      {
        field: 'active_offers',
        type: 'all',
        values: [true]
      }
    ]
  }
}

const labels = {
  region: 'Région'
  , secteur_activite: "Code NAF du secteur d‘activité"
  , appellation_libelle: "Apellation métier"
  , departement: "Département"
  , anfa_categorie: "Catégorie de recruteur"
  , anfa_contrat: "Type de contrat"
  , experience_exige: 'Expérience'
  , anfa_famille: "Famille métier"
  , active_offers: "Offres en cours uniquement"
}

export default function App() {
  return (
    <SearchProvider config={elasticConfig}>
      <WithSearch mapContextToProps={(state) => (state)}>
        {(state) => {
          const total = state.facets.alternance && state.facets.alternance[0].data.reduce(function(acc, value) { return (acc + value.count)}, 0)
          return (
            <div className="app">
              <Layout
                header={
                  <>
                    <div className="logo-boxes">
                      <div className="logo-box">
                        <img alt="Ministère du travail, de l'emploi et de l'insertion" src={'/img/pic.jpg'} />
                        <div className="pic">Plan d’investissement dans les compétences</div>
                      </div>
                      <div className="logo-box">
                        <img alt="ANFA" src={'/img/logo-anfa.svg'} />
                      </div>
                    </div>
                    <div className="introduction">
                      <p className="lead"><b>Bienvenue sur ScAn-jobauto</b>, l’outil de requête des offres d’emploi des métiers de la branche des services auto.</p>
                      <p style={{'margin-bottom': '1.5rem', 'font-style': 'normal'}}>Retrouvez, en temps réel, les annonces des métiers de la branche parues sur Pôle-Emploi et découvrez qui recrute, sous quel contrat, à quel endroit</p>
                      <SearchBox shouldClearFilters={false} inputProps={{ placeholder: "Recherche" }} inputView={SearchBoxInputView} />
                    </div>
                  </>
                }
                bodyHeader={(
                  <>
                    {state.wasSearched && !state.isLoading && <>
                      <div className="summary-heading">
                        {(state.totalResults > 0) &&
                          <div className="results-count"><b>{total && total.toLocaleString('fr')}</b> résultats<br /></div>
                        }
                        {(state.totalResults === 0) &&
                          <div className="results-count"><b>0</b> résultats<br /></div>
                        }
                      </div>
                      {(state.filters.length || state.resultSearchTerm) &&
                        <div className="results-summary">
                        <legend className="sui-facet__title">Critères de recherche sélectionnés</legend>
                        {state.resultSearchTerm && <div className="results-filter"><b>Recherche :</b>« {state.resultSearchTerm} »</div>}
                        {state.filters && state.filters.filter((f) => f.field !== 'geo_bounding_box').map((filter) => (
                          <div key={filter.field} className="results-filter">
                            <b>{labels[filter.field]} {" : "}</b>
                            {filter.values.map((val, i) => (
                              <>
                                {(i > 0) && ' ou '}
                                {
                                  (typeof val == "boolean")
                                  ? ((val) ? <em>{'oui'}</em> : <em>{'non'}</em>)
                                  : <em>{val} <span onClick={() => state.removeFilter(filter.field, val)} title={"Supprimer ce filtre"}>✕</span></em>
                                }
                              </>
                            ))}
                          </div>
                        ))}
                        {state.filters && state.filters.filter((f) => f.field === 'geo_bounding_box').length > 0 &&
                          <div key="geo_bounding_box" className="results-filter">
                            <b>Filtre cartographique activé</b>
                          </div>
                        }
                        {state.filters.filter((f) => f.field !== 'active_offers').length > 0 &&
                          <div className="button reset-search" onClick={() => { state.setSearchTerm("", {shouldClearFilters: true});state.setFilter('active_offers', true);}}><span>&#10005;</span>Réinitialiser la recherche et les filtres</div>
                        }
                      </div>}
                      {state.filters.filter((f) => f.field === 'geo_bounding_box').length > 0 &&
                          <div className="warning-message"><b>Attention :</b> certaines offres ne sont pas géolocalisées, elles sont donc automatiquement exclues des résultats lorsque le filtre cartographique est&nbsp;activé</div>
                      }
                    </>}
                  </>
                )}
                bodyContent={
                  <>
                    {!state.wasSearched && <div className="map-placeholder"><Spinner /></div>}
                    {state.wasSearched &&
                      <>
                        <MapContainer />
                        {state.isLoading && <Spinner />}
                        {!state.isLoading && <>
                          <Results
                            titleField="intitule"
                            urlField="url_origine"
                            resultView={ResultView}
                          />
                        </>}
                      </>
                    }
                  </>
                }
                bodyFooter={
                  <>
                    {!state.isLoading && <Paging />}
                  </>
                }
                sideContent={
                  <div>
                    {state.wasSearched && <>
                      <Sorting label={"Trier par"} sortOptions={SortOptions} />
                      <ActiveOffersCheckboxContainer />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" field={'anfa_famille'} label={labels['anfa_famille']} />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" isFilterable={true} field={'appellation_libelle'} label={labels['appellation_libelle']}  />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" field={'anfa_categorie'} label={labels['anfa_categorie']} />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" isFilterable={true} field={'secteur_activite'} label={labels['secteur_activite']}  />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" field={'anfa_contrat'} label={labels['anfa_contrat']} />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" field={'experience_exige'} label={labels['experience_exige']} />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" isFilterable={true} field={'region'} label={labels['region']} />
                      <Facet view={MultiCheckboxFacet} total={total} filterType="any" isFilterable={true} field={'departement'} label={labels['departement']} />
                      <ReactTooltip />
                    </>}
                  </div>
                }
              />
              {!state.wasSearched && <Spinner />}
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
