import React from "react";
import { SlideDown } from "react-slidedown";

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = () => {
      this.setState(state => ({ open: !state.open }));
    };
    this.state = {
      open: false,
    };
  }

  render() {
    let className = "dropdown-slidedown";
    let caption = this.state.open ? "Refermer" : this.props.title;
    let render = this.state.open;
    return (
      <div className={"dropdown-container"}>
        <SlideDown
          className={"pure-menu pure-menu-scrollable " + className}
          closed={!this.state.open}
        >
          {render && this.props.children}
        </SlideDown>
        <button
          className={"main-toggle button"}
          onClick={this.handleToggle}
        >
          {caption}
        </button>
      </div>
    );
  }
}

