export default async function runRequest(body) {
  const url = "https://scan-jobauto.anfa-auto.fr/api/search";
  // const url = "https://belgium.es.europe-west1.gcp.cloud.es.io:9243";
  // const url = "https://d8e18ff29d584e1f9cfd24158d5419c8.europe-west1.gcp.cloud.es.io:9243";
  const response = await fetch(url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body)
  });
  return response.json();
}
