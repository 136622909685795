import React from 'react';

import { withSearch } from "@elastic/react-search-ui";

export function ActiveOffersCheckboxContainer({ isLoading, geoData, filters, facets, addFilter, setFilter }) {
  const activeOffersOnly = filters.reduce(function(acc, filter) {
    if (filter.field === 'active_offers' && filter.values[0] === true) {
      acc = true;
    }
    return acc;
  }, false)
  return (
    <fieldset className="sui-facet">
      <div className="sui-multi-checkbox-facet">
        <label htmlFor="active_offers" className="sui-multi-checkbox-facet__option-label">
          <div className="sui-multi-checkbox-facet__option-input-wrapper">
            <input id="active_offers" type="checkbox" className="sui-multi-checkbox-facet__checkbox" checked={activeOffersOnly} onChange={() => setFilter('active_offers', !activeOffersOnly)}/>
            <span className="sui-multi-checkbox-facet__input-text">Offres en cours uniquement</span>
          </div>
        </label>
      </div>
    </fieldset>
  );
}



export default withSearch(
  ({ isLoading, geoData, filters, facets, addFilter, removeFilter, setFilter, a11yNotify }) => ({
    isLoading,
    geoData,
    filters,
    facets,
    addFilter,
    removeFilter,
    setFilter,
    a11yNotify
  })
)(ActiveOffersCheckboxContainer);
