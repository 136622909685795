import React from "react";
import Dropdown from './dropdown'

function hashCode(value) {
  var hash = 0;
  if (value.length === 0) {
    return hash;
  }
  for (var i = 0; i < value.length; i++) {
    var char = value.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export default function ResultView({ result }) {
  const date_format = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  const published = (result.date_creation)
    ? new Date(result.date_creation.raw).toLocaleDateString('fr-fr', date_format)
    : '[Date inconnue]';
  const updated = (result.date_actualisation && result.date_actualisation.raw !== result.date_creation.raw)
    ? new Date(result.date_actualisation.raw).toLocaleDateString('fr-fr', date_format)
    : null;
  const unpublished = (result.date_disparition)
    ? new Date(result.date_disparition.raw).toLocaleDateString('fr-fr', date_format)
    : null;
  const intitule = (result.intitule && result.intitule.snippet) ? result.intitule.snippet : ((result.intitule && result.intitule.raw) ? result.intitule.raw : '');
  const title = (result.entreprise_nom) ? result.entreprise_nom.raw + ' - ' + intitule : intitule;
  const description = (result.description && result.description.snippet) ? result.description.snippet : ((result.description && result.description.raw) ? result.description.raw : null);
  const classNames = (unpublished) ? 'result result-unpublished' : 'result';

  return (
    <li className={classNames}>
      <div
        className={'result-title'}
        dangerouslySetInnerHTML={{ __html:  title }}
      />
      <div className={'result-published'}>
        Publié le {published}
        {updated && <span className={'result-updated'}> - Mis à jour le {updated}</span>}
        {unpublished && <span className={'result-unpublished-date'}> - Dépublié le {unpublished}</span>}
        {result.commune &&
          <span className={'result-commune'}>
            {' • '} {result.commune.raw}
          </span>
        }
        {result.departement &&
          <span className={'result-departement'}>
            {' • '} {result.departement.raw}
          </span>
        }
      </div>
      <Dropdown title={'détails'}>
        <div className="result-details">

          <div className="result-details-side">
            {result.type_contrat && <div>{result.type_contrat.raw}</div>}

            {(result.duree_travail_libelle || result.complement_exercice)  &&
              <div>
                {result.duree_travail_libelle && <>{result.duree_travail_libelle.raw}<br/></>}
                {result.complement_exercice && result.complement_exercice.raw}
              </div>
            }

            {(result.salaire_libelle || result.salaire_complement1 || result.salaire_complement2 || result.salaire_commentaire)  &&
              <div>
                <h4>Salaire :</h4>
                {result.salaire_libelle && <>{result.salaire_libelle.raw}<br /></>}
                {result.salaire_commentaire && <>{result.salaire_commentaire.raw}<br /></>}
                {result.salaire_complement1 && <>{result.salaire_complement1.raw}<br /></>}
                {result.salaire_complement2 && <>{result.salaire_complement2.raw}</>}
              </div>
            }

            {(result.secteur_activite)  &&
              <div>
                <h4>Code NAF :</h4>
                {result.secteur_activite.raw}
              </div>
            }

          </div>

          <div className="result-details-main">

            {description && <div dangerouslySetInnerHTML={{ __html: description }} />}

            {result.competences_liste &&
              <div>
                <h4>Compétences</h4>
                <ul className="result-list">
                  {Object.entries(result.competences_liste.raw).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldKey, fieldValue]) => (
                    <>
                      {
                        // (fieldValue[1].match(/\d+/) && fieldValue[1].match(/\d+/)[0] === result.competences_liste.snippet.match(/\d+/)[0])
                        // ? <li dangerouslySetInnerHTML={{__html: result.competences_liste.snippet}}></li>
                        <li key={hashCode(fieldValue)}>{fieldValue}</li>
                      }
                    </>
                  ))}
                </ul>
              </div>
            }

            {result.formations_liste && result.formations_liste.raw &&
              <div>
                <h4>Formations</h4>
                <ul className="result-list">
                  {Object.entries(result.formations_liste.raw).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldKey, fieldValue]) => (
                    <li key={hashCode(fieldValue)}>{fieldValue}</li>
                  ))}
                </ul>
              </div>
            }

            {result.langues_liste && result.langues_liste.raw &&
              <div>
                <h4>Langues</h4>
                <ul className="result-list">
                  {Object.entries(result.langues_liste.raw).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldKey, fieldValue]) => (
                      <li key={hashCode(fieldValue)}>{fieldValue}</li>
                  ))}
                </ul>
              </div>
            }

            {result.permis_liste && result.permis_liste.raw &&
              <div>
                <h4>Permis</h4>
                <ul className="result-list">
                  {Object.entries(result.permis_liste.raw).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldKey, fieldValue]) => (
                      <li key={hashCode(fieldValue)}>{fieldValue}</li>
                  ))}
                </ul>
              </div>
            }

            {result.qualites_professionnelles &&
              <div>
                <h4>Savoir-être professionnels / qualités professionnelles</h4>
                <ul className="result-list">
                  {Object.entries(result.qualites_professionnelles.raw).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldKey, fieldValue]) => (
                      <li key={hashCode(fieldValue)}>{fieldValue}</li>
                  ))}
                </ul>
              </div>
            }

            {(result.experience_libelle || result.experience_commentaire) &&
              <div>
                <h4>Expérience</h4>
                <div>
                  {result.experience_libelle.raw}
                  {(result.experience_exige.raw === 'E') && <span className="required"></span>}
                </div>
              </div>
            }

            {result.id &&
              <a className={'result-external-link'} href={'https://candidat.pole-emploi.fr/offres/recherche/detail/' + result.id.raw} target="_blank" rel="noopener noreferrer">
                <img src={'/img/external.svg'} height={12} alt="lien externe" />
                Annonce pôle emploi
              </a>
            }

            {false && <Dropdown title={'Voir les données brutes API'}>
              <div className="sui-result__body">
                <ul className="sui-result__details">
                  {Object.entries(result).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldName, fieldValue]) => (
                      <li key={fieldName}>
                        <span className="sui-result__key">{fieldName}</span>{" "}
                        <span
                          className="sui-result__value"
                          dangerouslySetInnerHTML={{ __html: fieldValue.raw }}
                        />
                      </li>
                  ))}
                </ul>
              </div>
            </Dropdown>}
          </div>
        </div>
      </Dropdown>
    </li>
  );
}
