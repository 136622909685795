import PropTypes from "prop-types";
import React, { useState } from 'react';
import _ from 'lodash';
import wordings from './../wordings.js';
// eslint-disable-next-line
import ReactTooltip from 'react-tooltip';

import { FacetValue } from "@elastic/react-search-ui-views/es/types";
import { appendClassName, getFilterValueDisplay } from "@elastic/react-search-ui-views/es/view-helpers";

function MultiCheckboxFacet({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showLess,
  showSearch,
  onSearch,
  total,
  searchPlaceholder,
}) {
  const emptyOption = (_.find(options, {value: wordings.emptyLabel})) ? _.find(options, {value: wordings.emptyLabel}) : null;
  const hasSelectedFacet = (_.find(options, {selected: true})) ? true : false;
  const emptyCount = (emptyOption && emptyOption.count) ? emptyOption.count : 0;
  const nonEmptyStats = (!hasSelectedFacet) ? Math.floor(((total - emptyCount) / total) * 100) : 100;
  const tooltip = "Le champs « " + label + " » est renseigné dans " + nonEmptyStats + "% des offres correspondant à cette recherche";
  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      <legend className="sui-facet__title">{label} (<span data-tip={tooltip}>{nonEmptyStats}%</span>)</legend>

      {showSearch && (
        <div className="sui-facet-search">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder={"Rechercher"}
            onChange={e => {
              onSearch(e.target.value);
            }}
          />
        </div>
      )}

      <div className="sui-multi-checkbox-facet">
        {emptyOption &&
          <div
            style={{color: '#8b9bad', marginBottom: '.75em'}}
            className="sui-multi-checkbox-facet__option-label"
          >
            <div className="sui-multi-checkbox-facet__option-input-wrapper">
              <span className="sui-multi-checkbox-facet__input-text">
                {getFilterValueDisplay(emptyOption.value)}
              </span>
            </div>
            <span className="sui-multi-checkbox-facet__option-count" style={{color: '#8b9bad'}}>
              {emptyOption.count.toLocaleString("fr")}
            </span>
          </div>
        }
        {options.length < 1 && <div>Aucune option correspondante</div>}
        {options.map(option => {
          if (option.value === wordings.emptyLabel) {
            return (<div key={`${getFilterValueDisplay(option.value)}`}></div>);
          }
          const checked = option.selected;
          return (
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`facet_${label}${getFilterValueDisplay(
                option.value
              )}`}
              className="sui-multi-checkbox-facet__option-label"
            >
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  id={`facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() =>
                    checked ? onRemove(option.value) : onSelect(option.value)
                  }
                />
                <span className="sui-multi-checkbox-facet__input-text">
                  {getFilterValueDisplay(option.value)}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
                {option.count.toLocaleString("fr")}
              </span>
            </label>
          );
        })}
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Voir plus d'options"
        >
          + Voir plus
        </button>
      )}
    </fieldset>
  );
}

MultiCheckboxFacet.propTypes = {
  label: PropTypes.string.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(FacetValue).isRequired,
  showMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  showSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string
};

export default MultiCheckboxFacet;
